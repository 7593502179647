import React from "react";
import "./ContentCard.css";

const getVideoThumbnailFor = (videoId) => {
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

const getVideoUrlFor = (videoId) => {
  return `https://www.youtube.com/watch?v=${videoId}`;
};

const ContentCard = (props) => {
  return (
    <div className="content-card">
      <div className="content-preview">
        <a href={getVideoUrlFor(props.videoId)}>
          <img
            alt="video thumbnail"
            src={getVideoThumbnailFor(props.videoId)}
          />
        </a>
      </div>
      <div className="content-details">
        <h4>{props.title}</h4>
        <h4>by {props.author}</h4>
        <p>{props.description}</p>
        <p id="content-length">Length: 40:05</p>
      </div>
    </div>
  );
};

export default ContentCard;
