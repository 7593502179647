import "./styles.css";
import ContentCard from "./ContentCard";

export default function App() {
  const favoriteVideos = [
    {
      author: "Darin Doria",
      title: "Code With Me | 1hr Real Time w/ Lo-Fi Beats",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "Pn2mzyU52yI"
    },
    {
      title: "Chill Lofi Mix [chill lo-fi hip hop beats]",
      author: "Settle",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "CLeZyIID9Bo"
    },
    {
      author: "agenomicsphd",
      title: "study and code with me 1 hour | lofi music",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "Y2dVQIFD6Bc"
    },
    {
      author: "The Hanoi Chamomile",
      title:
        "2-HOUR STUDY WITH ME ON A RAINY DAY | 🎹 Calm Piano, Soft Rain | Pomodoro (25/5)",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "wUGbUERmhJM"
    },
    {
      author: "Củ Lạc",
      title: "📚🧑‍💻 study/code with me 1 hour with lofi music 🎶",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "u2hLLHJov9w"
    },
    {
      author: "nintendo and chill",
      title: "ｎｉｎｔｅｎｄｏ　ａｎｄ　ｌｏｆｉ",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "AvhplYM46Fc"
    },
    {
      author: "Abao in Tokyo",
      title:
        "📚2-HOUR LATE NIGHT STUDY / gentle rain🌧 + lofi music / 50 minute Pomodoro / with timer+bell",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "sca4VG9b0NY"
    },
    {
      author: "Miyuki Studies",
      title: "chill / relax / study music | studio ghibli lo-fi jazz mix",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "AZals4U6Z_I"
    },
    {
      author: "Wow Annie",
      title: "studio ghibli playlist (relax, sleep, study)🌱",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "PHklnuOvxfg"
    },
    {
      author: "The Sherry Formula",
      title:
        "🌿 4-HOUR STUDY MUSIC PLAYLIST/ Relaxing Lofi / Cozy Evening Deep Focus Pomodoro Timer/ Study With Me",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "eQOJzxe76qk"
    },
    {
      author: "Zelda-Kirby Fan",
      title: "Chill and Calm Nintendo Music for Calm Stuff and Study",
      description:
        "A great video to code along to. Relaxing vibes and chill music.",
      videoId: "t4u_PLGdRqE"
    }
  ];

  return (
    <div>
      <div className="app-header">
        <h1>Deep Work Tunes</h1>
        <p id="header-text">
          Check out some of my favorite videos. I like listening to these tunes
          when I'm working.
        </p>
      </div>

      <div className="content">
        {favoriteVideos.map((video) => {
          return (
            <ContentCard
              author={video.author}
              videoId={video.videoId}
              title={video.title}
              description={video.description}
            />
          );
        })}
      </div>
    </div>
  );
}
